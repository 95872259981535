<template>
	<div>
		<s-crud
			title="Salidas de Tipo de Congelamiento"
			:config="config"
			:filter="filter"
			add
			 @save="save($event)"
			edit 
			remove
			search-input
		> 
			<template v-slot="props">
				<v-container>
					<v-row>
					
                        <v-col cols="4" lg="4" md="6" sm="12">
                            <s-text
								label="Nombre"
								v-model="props.item.CecDescription"
								ref="txtNombre"
							></s-text>
                        </v-col>

                        <!-- <v-col cols="4" lg="4" md="6" sm="12">
                            <s-select-definition
								label="Cultivo"
								v-model="props.item.typeCultive"
                                :def="1173"
                                @input="inputProdCategory($event)"
								ref="txtCultive"
                            ></s-select-definition>
                        </v-col> -->

                        <v-col cols="3" lg="3" md="6" sm="12">	
                            <!-- <s-select
								:items="ProductionCategory"
								item-value="PdcID"
								item-text="PdcDescription"
								label="Tipo de Congelamiento"
								v-model="props.item.PdcID"
                                :cultiveID="props.item.typeCultive"
                                ref="txtCategoria"
							/>						 -->
							<s-select-definition
                                label="Tipo de Congelamiento"
                                :def="1439"
                                v-model="props.item.TypeFreezing"
                               
                              
                               >
                            </s-select-definition>
						</v-col>

						<!-- <v-col cols="3" lg="3" md="6" sm="12">	
                            <s-text
								
								label="Color"
								v-model="props.item.CecColor"
                                type="color"
                                ref="txtColor"
							/>						
							
						</v-col> -->

                        <v-col cols="4" lg="4" md="6" sm="12">
                            <s-select-definition
								label="Estado"
								v-model="props.item.CecState"
                                :def="1154"
                            ></s-select-definition>
                        </v-col>

                    </v-row>
				</v-container>
			</template>
			<template v-slot:CecState="{ row }">
				<v-chip x-small=""  :color="row.CecState == 1 ? 'success': 'error'" >
					{{ row.CecState == 1 ? 'Activo': 'Inactivo' }}
				</v-chip>
        	</template>
			<template v-slot:CecColor="{ row }">
				<v-chip small :color="row.CecColor"></v-chip>
        	</template>

		</s-crud>
	</div>
</template>



<script>
	import _sFrzTypeExitCategoryService from "@/services/FrozenProduction/FrzTypeExitCategory.js"
    // import _sProductionCategory from '../../../services/FrozenProduction/ProductionCategory.js';

	export default {
		data() {
			return {
                ProductionCategory: [],
				CttProjectedDate: true,
				config: {
					model: {
						CecID: "ID",
						CecState:"",
						CecColor:""
					},
					service: _sFrzTypeExitCategoryService,
					headers: [
						{text: "ID", value: "CecID"},
						{text: "Descripcion", value: "CecDescription"},
                        // {text: "Cultivo", value: "typeCultiveN"},
						{text: "Tipo de Congelamiento", value: "TypeFreezingName"},						
						{text: "Estado", value: "CecState"},
						// {text: "Color", value: "CecColor"},
					]
				},
				filter: { CecDescription: 0},
			}
		},

		methods: {
			inputProdCategory(typeCultive){
                console.log(typeCultive)
                _sFrzTypeExitCategoryService.list( { typeCultive }, this.$fun.getUserID()).then(r =>{
                if(r.status == 200)
                {
                    this.ProductionCategory = r.data;                    
                }
            });   
            },
			save(item) {
                if(item.CecDescription == "")
				{
					this.$fun.alert("Ingrese el nombre", "warning");
					this.$refs.txtNombre.focus();
					return;
				}
				// if(item.typeCultive == "")
				// {
				// 	this.$fun.alert("Ingrese tipo de Cultivo", "warning");
				// 	this.$refs.txtCultive.focus();
				// 	return;
				// }
				// if(item.PdcID =="" || !item.PdcID)
				// {
				// 	this.$fun.alert("Ingrese tipo de Congelamiento", "warning");
				// 	this.$refs.txtCategoria.focus();
				// 	return;
				// }
				// if(!item.CecColor)
				// {
				// 	this.$fun.alert("Ingrese Color", "warning");
				// 	this.$refs.txtColor.focus();
				// 	return;
				// }
				// if(!item.PdcDescription)
				// {
				// 	this.$fun.alert("Ingrese tipo de Categoria", "warning");
				// 	this.$refs.txtCategoria.focus();
				// 	return;
				// }
				
				

				item.save();	
			}
		},

		created(){
          
                    
		}
	}
</script>